exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-for-you-js": () => import("./../../../src/pages/for-you.js" /* webpackChunkName: "component---src-pages-for-you-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-method-js": () => import("./../../../src/pages/method.js" /* webpackChunkName: "component---src-pages-method-js" */),
  "component---src-pages-social-fit-js": () => import("./../../../src/pages/social-fit.js" /* webpackChunkName: "component---src-pages-social-fit-js" */)
}

